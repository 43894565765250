import React, { useState, useEffect } from "react";
import Ruleta from "./components/Ruleta";
import { foodData, restaurantData } from "./components/Datos";
import { motion, AnimatePresence } from "framer-motion";
import { Card, CardContent, CardHeader, CardTitle } from "./components/ui/card";

type FoodType = keyof typeof restaurantData;

function App() {
  const [mustSpinFood, setMustSpinFood] = useState(false);
  const [mustSpinRestaurant, setMustSpinRestaurant] = useState(false);
  const [prizeFoodIndex, setPrizeFoodIndex] = useState(0);
  const [prizeRestaurantIndex, setPrizeRestaurantIndex] = useState(0);
  const [selectedFood, setSelectedFood] = useState<FoodType | null>(null);
  const [selectedRestaurant, setSelectedRestaurant] = useState<string | null>(null);

  const handleFoodSpinClick = () => {
    const newPrizeIndex = Math.floor(Math.random() * foodData.length);
    setPrizeFoodIndex(newPrizeIndex);
    setMustSpinFood(true);
  };

  const handleRestaurantSpinClick = () => {
    if (!selectedFood) return;
    const restaurants = restaurantData[selectedFood];
    if (!restaurants) return;
    const newPrizeIndex = Math.floor(Math.random() * restaurants.length);
    setPrizeRestaurantIndex(newPrizeIndex);
    setMustSpinRestaurant(true);
  };

  return (
    <div className="min-h-screen bg-gray-100 p-4 md:p-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl md:text-5xl font-bold mb-8 text-center text-gray-800">
          Ruleta: ¿Qué vas a comer hoy?
        </h1>

        {/* Usamos `motion.div` para animar el cambio en las columnas */}
        <motion.div
          className="grid gap-8"
          initial={{ gridTemplateColumns: "1fr" }}
          animate={{
            gridTemplateColumns: selectedFood ? "1fr 1fr" : "1fr",
          }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
          style={{ display: "grid" }}
        >
          <Card className="bg-white shadow-lg">
            <CardHeader className="text-center">
              <CardTitle className="text-2xl text-gray-800">Comida</CardTitle>
            </CardHeader>
            <CardContent>
              <Ruleta
                data={foodData}
                mustSpin={mustSpinFood}
                prizeIndex={prizeFoodIndex}
                onSpinClick={handleFoodSpinClick}
                onStopSpinning={() => {
                  setMustSpinFood(false);
                  setSelectedFood(foodData[prizeFoodIndex].option as FoodType);
                }}
                buttonLabel="Girar comida"
              />
            </CardContent>
          </Card>

          <AnimatePresence>
            {selectedFood && restaurantData[selectedFood]?.length > 0 && (
              <motion.div
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 50 }}
                transition={{ duration: 0.5 }}
              >
                <Card className="bg-white shadow-lg">
                  <CardHeader className="text-center">
                    <CardTitle className="text-2xl text-gray-800">Restaurante</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <h2 className="text-xl md:text-2xl font-semibold mb-4 text-center text-gray-700">
                      Seleccionaste: {selectedFood}
                    </h2>
                    <h3 className="text-lg md:text-xl mb-4 text-center text-gray-600">
                      Ahora elige un restaurante:
                    </h3>
                    <Ruleta
                      data={restaurantData[selectedFood] || []}
                      mustSpin={mustSpinRestaurant}
                      prizeIndex={prizeRestaurantIndex}
                      onSpinClick={handleRestaurantSpinClick}
                      onStopSpinning={() => {
                        setMustSpinRestaurant(false);
                        const selectedRestaurantOption =
                          restaurantData[selectedFood]?.[prizeRestaurantIndex]?.option || null;
                        setSelectedRestaurant(selectedRestaurantOption);
                      }}
                      buttonLabel="Girar restaurantes"
                      backgroundColors={["#FFAA33", "#33AAFF", "#AAFF33"]}
                      textColors={["#000000"]}
                    />
                  </CardContent>
                </Card>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>

        {selectedRestaurant && (
          <motion.div
            className="mt-8 text-center"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.5 }}
          >
            <h2 className="text-2xl md:text-3xl font-bold mb-2 text-green-600">
              ¡Tu elección final es: {selectedFood} en {selectedRestaurant}!
            </h2>
            <p className="text-xl text-gray-700">¡Disfruta tu comida!</p>
          </motion.div>
        )}
      </div>
    </div>
  );
}

export default App;
