import React from 'react';
import { Wheel } from 'react-custom-roulette';
import { WheelData } from "react-custom-roulette/dist/components/Wheel/types";
import { motion } from 'framer-motion';
import { Button } from "./ui/button";

interface RuletaProps {
  data: WheelData[];
  mustSpin: boolean;
  prizeIndex: number;
  onSpinClick: () => void;
  onStopSpinning: () => void;
  buttonLabel: string;
  backgroundColors?: string[];
  textColors?: string[];
}

const Ruleta: React.FC<RuletaProps> = ({
  data,
  mustSpin,
  prizeIndex,
  onSpinClick,
  onStopSpinning,
  buttonLabel,
  backgroundColors = ["#ff8f43", "#70bbe0", "#0b3351", "#f9dd50", "#ff4b5c", "#34c759", "#ff9900", "#0096ff"],
  textColors = ["#ffffff"],
}) => {
  return (
    <div className="flex flex-col items-center">
      <div className="mb-4 w-full max-w-xs flex justify-center">
        <Wheel
          mustStartSpinning={mustSpin}
          prizeNumber={prizeIndex}
          data={data}
          onStopSpinning={onStopSpinning}
          backgroundColors={backgroundColors}
          textColors={textColors}
          fontSize={14}
          outerBorderColor={"#ffd700"}
          outerBorderWidth={10}
          innerRadius={10}
          radiusLineColor={"#ffd700"}
          radiusLineWidth={5}
          perpendicularText={false}
          textDistance={60}
        />
      </div>
      <motion.div
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <Button
          className="px-6 py-3 bg-yellow-500 text-black font-bold rounded-full shadow-lg hover:bg-yellow-400 transition duration-300 ease-in-out"
          onClick={onSpinClick}
        >
          {buttonLabel}
        </Button>
      </motion.div>
    </div>
  );
};

export default Ruleta;

