export const foodData = [
    { option: "Pizza" },
    { option: "Sushi" },
    { option: "Hamburguesas" },
    { option: "Empanadas" },
    { option: "Ensaladas" },
    { option: "Carne" },
    { option: "Pollo" },
    { option: "Veggie" },
    { option: "Lomitos" },
    { option: "Milanesas" },
    { option: "Pastas" },
    { option: "Panchos" },
    { option: "Wraps" }
  ];
  
  export const restaurantData = {
    Pizza: [
      { option: "Kentucky" },
      { option: "Pizzeria Popular" },
      { option: "Guerrin" },
      { option: "Palacio de la pizza" },
      { option: "El Cuartito" },
    ],
    Sushi: [
      { option: "Sushi Pop" },
      { option: "Ikansushi" },
      { option: "Fabric Sushi Downtown" },
    ],
    Hamburguesas: [
      { option: "Burger King" },
      { option: "Mc Donalds" },
      { option: "Big Pons" },
      { option: "Mostaza" },
      { option: "Wendy's" },
    ],
    Empanadas: [
      { option: "El Noble" },
      { option: "La Cocina" },
      { option: "El Sanjuanino" },
    ],
    
    Ensaladas: [
      { option: "Green Eat" },
      { option: "Pura Vida" },
      { option: "Le Pain Quotidien" },
    ],
    
    Carne: [
      { option: "Don Julio" },
      { option: "La Cabrera" },
      { option: "Cabaña Las Lilas" },
    ],
    
    Pollo: [
      { option: "KFC" },
      { option: "Popeyes" },
      { option: "Pollo Campero" },
    ],
    
    Veggie: [
      { option: "Green Eat" },
      { option: "Bio Solo Orgánico" },
      { option: "Pura Vida" },
    ],
    
    Lomitos: [
      { option: "Betos" },
      { option: "El Griego" },
      { option: "Lomitería 123" },
    ],
    
    Milanesas: [
      { option: "El Antojo" },
      { option: "El Club de la Milanesa" },
      { option: "La Farola" },
    ],
    
    Pastas: [
      { option: "Broccolino" },
      { option: "La Parolaccia" },
      { option: "Spiagge di Napoli" },
    ],
    
    Panchos: [
      { option: "Gringo" },
      { option: "Dogg" },
      { option: "Pancho Gourmet" },
      { option: "Superpancho Express" },
    ],
    
    Wraps: [
      { option: "Green Eat" },
      { option: "Pura Vida" },
      { option: "Subway" },
    ],    
  };
  
  